
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import IHttpRequest from "src/http/http-request";

/**
 * 高德服务。
 * @class
 * @version 1.0.0
 */
export default class GaodeService extends ServiceBase
{
    /*
     * 高德服务实例
     * @private
     * @return GaodeService
     */
    private static _instance: GaodeService;

    /**
     * 高德服务实例
     * @static
     * @property
     * @returns GaodeService
     */
    public static get instance(): GaodeService
    {
        if(!this._instance)
        {
            this._instance = new GaodeService();
        }
        
        return this._instance;
    }

    /**
     * 获取当前定位信息
     * @public
     * @param {params}
     * @returns {Promise<any>}
     */
    public async getGeolocation(): Promise<any>
    {
        return new Promise<any>((resolve, reject) =>
        {
            let data =
            {
                city: "",
                lng: null,
                lat: null
            };

            AMap.plugin("AMap.Geolocation", function() {
                let geolocation = new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 100,
                    // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                    buttonOffset: new AMap.Pixel(0, 0),
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    buttonPosition: "RB"
                });
                
                geolocation.getCurrentPosition((status: string,result: any) =>
                {
                    if(status === "complete")
                    {
                        data.lat = result.position.lat;
                        data.lng = result.position.lng;
                        if(data.city)
                        {
                            resolve(data);
                        }
                    }
                    else
                    {
                        data.lat = 22.540269;
                        data.lng = 114.012325;
                        data.city = "深圳";
                        resolve(data);
                       
                    }
                });
                geolocation.getCityInfo((status: string,result: any) =>
                {
                    if(status === "complete")
                    {
                        if(result.city.substr(result.city.length - 1,1) === "市")
                        {
                            data.city = result.city.substr(0, result.city.length - 1);
                        }
                        else
                        {
                            data.city = result.city;
                        }

                        if(data.lat)
                        {
                            resolve(data);
                        }
                    }
                    else
                    {
                        data.lat = 22.540269;
                        data.lng = 114.012325;
                        data.city = "深圳";
                        resolve(data);
                    }
    
                });
                
            });
        });
        
    }
    
}
