
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 文件服务
 * @class
 * @version 1.0.0
 */
export default class FileService extends ServiceBase
{
    /*
     * 服务实例
     * @private
     * @return WechatService
     */
    private static _instance: FileService;

    /**
     * 服务实例
     * @static
     * @property
     * @returns WechatService
     */
    public static get instance(): FileService
    {
        if(!this._instance)
        {
            this._instance = new FileService();
        }
        
        return this._instance;
    }

    /**
     * 上传
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async uploadFile(data: any): Promise<IHttpResponse> {
        let response = await this.apis.uploadFile({
            data
        });
        return response;
    }
}
