
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 报告服务
 * @class
 * @version 1.0.0
 */
export default class ReportService extends ServiceBase
{
    /*
     * 报告服务实例
     * @private
     * @return ReportService
     */
     private static _instance: ReportService;

     /**
      * 报告实例
      * @static
      * @property
      * @returns ReportService
      */
     public static get instance(): ReportService
     {
         if(!this._instance)
         {
             this._instance = new ReportService();
         }
         
         return this._instance;
    }

    
    /**
     * 获取报告列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getReportList(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getReportList({
            data: data
        });

        return response;
    }

    /**
     * 获取json报告
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getReportJson(orderNo: string): Promise<IHttpResponse>
    {

        let response = await this.apis.getReportJson({
            params:{orderNo}
        });

        return response;
    }

    /**
     * 获取PDF报告
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getReportPDF(orderNo: string): Promise<IHttpResponse>
    {

        let response = await this.apis.getReportPDF({
            params:{orderNo}
        });

        return response;
    }
}
