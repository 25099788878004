import Type from "src/utils/type";

export
{
    ServiceType
};

/**
 * 订单状态
 * @enum
 * @version 1.0.0
 */
enum ServiceType
{
    /**
     * 体检
     * @member
     */
    physical = 1,

    /**
     * 口腔
     * @member
     */
    oralCavity,
    
    /**
     * 绿通
     * @member
     */
    green,

    /**
     * 眼科
     * @member
     */
    ophthalmology,

    /**
     * 陪诊
     * @member
     */
    escort,

    /**
     * 基因
     * @member
     */
    gene,

    /**
     * 耳鼻喉
     * @member
     */
    otolaryngology,

    /**
     * 问诊
     * @member
     */
    diagnosis,

    /**
     * 细胞存储
     * @member
     */
    cellStorage,
   
}

Type.setMetadata(ServiceType,
{
    physical:
    {
        description: "体检"
    },
    oralCavity:
    {
        description: "口腔"
    },
    green:
    {
        description: "绿通"
    },
    ophthalmology:
    {
        description: "眼科"
    },
    escort:
    {
        description: "陪诊"
    },
    gene:
    {
        description: "基因"
    },
    otolaryngology:
    {
        description: "耳鼻喉"
    },
    diagnosis:
    {
        description: "问诊"
    },
    cellStorage:
    {
        description: "细胞存储"
    }
});
