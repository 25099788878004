const personalCenter = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/personal-center.vue")), "user");
const updatePwd = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/update-pwd.vue")), "user");
const userSetting = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/user-setting.vue")), "user");
const retrievePwd = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/retrieve-pwd.vue")), "user");
const systemFeedback = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/system-feedback.vue")), "user");
export default
[
    {
        path: "/personal-center",
        name: "personal-center",
        component: personalCenter,
        meta:
        {
            tabbarName: "personal-center"
        }
    },
    {
        path: "/retrieve-pwd",
        name: "retrievePwd",
        component: retrievePwd
    },
    {
        path: "/update-pwd",
        name: "updatePwd",
        component: updatePwd
    },
    {
        path: "/user/setting",
        name: "userSetting",
        component: userSetting
    },
    {
        path: "/user/system-feedback",
        name: "systemFeedback",
        component: systemFeedback
    }
];
