
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 微信服务
 * @class
 * @version 1.0.0
 */
export default class WechatService extends ServiceBase
{
    /*
     * 微信服务实例
     * @private
     * @return WechatService
     */
    private static _instance: WechatService;

    /**
     * 微信实例
     * @static
     * @property
     * @returns WechatService
     */
    public static get instance(): WechatService
    {
        if(!this._instance)
        {
            this._instance = new WechatService();
        }
        
        return this._instance;
    }

    /**
     * 获取微信jssdk
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getWechatJSSDK(currentUrl: string): Promise<IHttpResponse>
    {

        let response = await this.apis.getWechatJSSDK({
            data:
            {
                url: currentUrl
            }
        });

        return response;
    }
}
