const orderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-detail.vue")), "order");
const orderSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-submit.vue")), "org");
const orderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-list.vue")), "order");

export default
[
    {
        path: "/order/detail/:id",
        name: "orderDetail",
        component: orderDetail,
        meta:
        {
            title: "订单详情"
        }
    },
    {
        path: "/order/submit",
        name: "submit-order",
        component: orderSubmit,
        meta:
        {
            title: "提交订单"
        }
    }
    ,
    {
        path: "/order/list",
        name: "order-list",
        component: orderList,
        meta:
            {
                title: "全部订单"
            }
    }
];
