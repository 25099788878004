






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { OrganizationService } from "src/services";
import dayjs from "dayjs";

/**
 * 排期日历组件
 * @class
 * @version 1.0.0
 */
@Component
export default class Scheduling extends Vue
{
    /**
     * 套餐id
     * @private
     * @returns string
     */
    @Prop({type: [String, Number], default: ""})
    private packageId: string | number;

    /**
     * 套餐编号
     * @private
     * @returns string
     */
    @Prop({type: [String, Number], default: ""})
    private packageCode: string | number;

    /**
     * 机构id
     * @private
     * @returns string
     */
    @Prop({type: [String, Number], default: ""})
    private hospitalId: string | number;

    /**
     * 机构编号
     * @private
     * @returns string
     */
    @Prop({type: [String, Number], default: ""})
    private hospitalCode: string | number;

    /**
     * 是否显示
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default: false})
    private value: boolean;

    /**
     * 排期数据
     * @private
     * @returns any
     */
    private schedulingData: any = [];

    /**
     * 最小日期
     * @private
     * @returns any
     */
    private defaultDate: any = dayjs("1970/01/01").toDate();

    /**
     * 最小日期
     * @private
     * @returns string
     */
    private minDate: string = "";

    /**
     * 最大日期
     * @private
     * @returns string
     */
    private maxDate: string = "";

    /**
     * 是否显示日期弹框 
     * @private
     * @returns boolean
     */
    private get showDate(): boolean
    {
        return this.value;
    }

    /**
     * 返回弹框状态 
     * @private
     */
    private set showDate(value: boolean)
    {
        this.$emit("input", value);
    }

    /**
     * 自定义排期格式
     * @private
     * @returns void
     */
    private formatter(day: any): void
    {
        let date = dayjs(day.date).format("YYYY/MM/DD");

        let selectDate =  this.schedulingData.filter(item => dayjs(item.startTime).isSame(dayjs(date)));

        if(selectDate && selectDate.length > 0 && selectDate[0].personCount > 0)
        {
            day.topInfo = `剩余${selectDate[0].personCount}`;
        }
        else if(selectDate && selectDate.length > 0 && selectDate[0] && selectDate[0].personCount === 0)
        {
            day.topInfo = "已约满";
            day.type = "disabled";
        }
        else
        {
            day.type = "disabled";
            
        }

        return day;
    }

    /**
     * 日期确认选择
     * @private
     * @returns string
     */
    private onDateConfirm(date: any): void
    {
        if(dayjs("1970/01/01").isSame(dayjs(date)))
        {
            this.$emit("onDateConfirm", null);
        }
        else
        {
            this.$emit("onDateConfirm", date);
        }
    }

    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
    @Watch("showDate")
    private onShowDate(value: boolean): void
    {
        if(value)
        {
            this.getMedicalOrgDateInfo();
        }
        
    }

    /**
     * 获取某个机构排期
     * @private
     * @returns void
     */
    private async getMedicalOrgDateInfo(): Promise<void>
    {
        try
        {

            let {content: result} =  await OrganizationService.instance.getMedicalOrgDateInfo({
                packageId: this.packageId,
                packageCode: this.packageCode,
                hospitalId: this.hospitalId,
                hospitalCode: this.hospitalCode
            });
            
            if(result.data)
            {
                console.log(result.data)
                this.schedulingData = result.data.schedules || [];
                this.minDate = result.data.startTime;
                this.maxDate = result.data.endTime;
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }
}
