const powerReportIntroduce = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/powerReport/introduce.vue")), "powerReport");
const powerReportForm = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/powerReport/form.vue")), "powerForm");
const powerReportOrderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/powerReport/order-list.vue")), "powerForm");
export default
[
    {
        path: "/powerReport-introduce",
        name: "powerReportIntroduce",
        component: powerReportIntroduce
    },
    {
        path: "/powerReport-form",
        name: "powerReportForm",
        component: powerReportForm
    },
    {
        path: "/powerReport-orderList",
        name: "powerReportOrderList",
        component: powerReportOrderList
    }
];
