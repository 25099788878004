
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 订单 服务。
 * @class
 * @version 1.0.0
 */
export default class OrderService extends ServiceBase
{
    /*
     * 订单服务实例
     * @private
     * @return GaodeService
     */
    private static _instance: OrderService;

    /**
     * 订单服务实例
     * @static
     * @property
     * @returns OrderService
     */
    public static get instance(): OrderService
    {
        if(!this._instance)
        {
            this._instance = new OrderService();
        }
        
        return this._instance;
    }

    /**
     * 提交订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async submitOrder(models: any): Promise<IHttpResponse>
    {
        let response = await this.apis.submitOrder({
            data: models
        });

        return response;
    }

    /**
     * 获取订单详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrderDetail(orderId: number): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrderDetail({
            params:
            {
                id: orderId
            }
        });

        return response;
    }

    /**
     * 修改订单预约日期
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async modifiedOrderDate(orderId: number, orderedDate: string): Promise<IHttpResponse>
    {
        let response = await this.apis.modifiedOrderDate({
            data:
            {
                id: orderId,
                changeTime: orderedDate
            }
        });

        return response;
    }

    /**
     * 取消订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async cancelOrder(orderId: number): Promise<IHttpResponse>
    {
        let response = await this.apis.cancelOrder({
            data:
            {
                id: orderId
            }
        });

        return response;
    }

    /**
     * 继续支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async proceedPay(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.proceedPay({
            data:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 订单列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrderList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrderList({
            data: data
        });

        return response;
    }
    
}
