import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import org from "./modules/org";
import packages from "./modules/package";
import order from "./modules/order";
import family from "./modules/family";
import report from "./modules/report";
import user from "./modules/user";
import power from "./modules/power";
import service from "./modules/service";
import channel from "./modules/channel";
import powerReport from "./modules/powerReport";

import cellStockpile from "./modules/cellStockpile";
const login = () => import("src/views/login.vue");
const home = () => import("src/views/home.vue");

Vue.use(VueRouter);

const routes: Array<RouteConfig> =
[
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/home",
        name: "home",
        component: home
    },
    // {
    //     path: "/zjltfw",
    //     name: "zjltfw",
    //     component: zjltfw
    // },
    // {
    //     path: "/zjlvfw",
    //     name: "zjlvfw",
    //     component: zjlvfw
    // },
    // {
    //     path: "/mbgy",
    //     name: "mbgy",
    //     component: mbgy
    // },
    ...power,
    ...org,
    ...packages,
    ...order,
    ...family,
    ...report,
    ...user,
    ...service,
    ...channel,
    ...cellStockpile,
    ...powerReport
];

let urlArr = location.pathname.split("/");
let appid = urlArr[1] || "Fv2ffM4sC5";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL + `${appid}/`,
    routes
});

router.beforeEach((to, from, next) =>
{
    let urlArr = location.pathname.split("/");
    let appid = urlArr[1] || "Fv2ffM4sC5";
    let appidStorage = localStorage.getItem("appid");
    
    if(appid !== appidStorage)
    {
        localStorage.setItem("appid", appid);
    }

    next();
});

export default router;
