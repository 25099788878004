
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 线下报告解读服务
 * @class
 * @version 1.0.0
 */
export default class OfflineReportExplanationService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return OfflineReportExplanationService
     */
    private static _instance: OfflineReportExplanationService;

    /**
     * 服务实例
     * @static
     * @property
     * @returns OfflineReportExplanationService
     */
    public static get instance(): OfflineReportExplanationService
    {
        if(!this._instance)
        {
            this._instance = new OfflineReportExplanationService();
        }
        
        return this._instance;
    }

    /**
     * 新增
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async addOfflineReportExplanation(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.addOfflineReportExplanation({
            data: data
        });

        return response;
    }

    /**
     * 取消
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async cancelOfflineReportExplanation(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.cancelOfflineReportExplanation({
            params: {id}
        });

        return response;
    }

    /**
     * 获取某个用户线下报告列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async offlineReportExplanationList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.offlineReportExplanationList({
            data: data
        });

        return response;
    }

    /**
     * 获取线下报告解读详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOfflineReportExplanation(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.getOfflineReportExplanation({
            params: {id}
        });

        return response;
    }

    /**
     * 激活线上问诊套餐
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async activationDiagnosis(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.activationDiagnosis({
            data: data
        });

        return response;
    }

    /**
     * 获取用户是否已经激活问诊套餐卡
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getMemberDiagnosis(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.getMemberDiagnosis({
            params: {id}
        });

        return response;
    }
    
}
