const packageDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/package-detail.vue")), "package");
const packageList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/package-list.vue")), "package");
const optionalPackage = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/optional-package.vue")), "package");

export default
[
    {
        path: "/package-detail",
        name: "package-detail",
        component: packageDetail,
        meta:
        {
            title: "套餐详情"
        }
    },
    {
        path: "/package-list",
        name: "package-list",
        component: packageList,
        meta:
        {
            title: "选择套餐"
        }
    },
    {
        path: "/optional-package",
        name: "optional-package",
        component: optionalPackage,
        meta:
        {
            title: "自选加项"
        }
    }
];
