import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import "./registerServiceWorker";
import vant from "vant";
import "vant/lib/index.css";
import filters from "src/filters";
import commonComponents from "src/components";
import Vconsole from "vconsole";
Vue.use(commonComponents);
Vue.use(vant);
Vue.config.productionTip = false;

Object.keys(filters).forEach(key =>
{
    Vue.filter(key, filters[key]);
});
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
  router,
  store,
  render: (h: any) => h(App)
}).$mount("#app");
