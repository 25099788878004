
import API from "./urls";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import IHttpRequest from "src/http/http-request";

/**
 * 发送ajax请求
 * @param {any} options 请求参数
 * @param {string} url 请求地址
 * @param {string} method 请求类型
 * @returns {any}
 */
const send = (url: string, options?: IHttpRequest, method: string = "post"): IHttpResponse =>
{
    // 如果未传入url以及参数抛出异常
    if (!url)
    {
        throw new Error("url is null.");
    }

    // 返回promise实例由用户处理
    return HttpClient.instance[method](
    {
        url,
        ...options
    });
};

/**
 * 接口类
 * @version 1.0.0
 */
export default class Apis
{
    public uploadFile = (option?: IHttpRequest) => send(API.uploadFile, option);
    // 以下为团检接口
    public login = (option?: IHttpRequest) => send(API.login, option);
    public getOrgIngo = (option?: IHttpRequest) => send(API.getOrgIngo, option, "get");
    public getHomeInfo = (option?: IHttpRequest) => send(API.getHomeInfo, option);
    public getpackageList = (option?: IHttpRequest) => send(API.getpackageList, option);
    public getMedicalpackage = (option?: IHttpRequest) => send(API.getMedicalpackage, option);
    public getPackageInfo = (option?: IHttpRequest) => send(API.getPackageInfo, option);
    public getWechatJSSDK = (option?: IHttpRequest) => send(API.getWechatJSSDK, option,"get");
    public getHospitalList = (option?: IHttpRequest) => send(API.getHospitalList, option);
    public locationCity = (option?: IHttpRequest) => send(API.locationCity, option, "get");
    public getMedicalOrgDateInfo = (option?: IHttpRequest) => send(API.getMedicalOrgDateInfo, option);
    public submitOrder = (option?: IHttpRequest) => send(API.submitOrder, option);
    public smsSend = (option?: IHttpRequest) => send(API.smsSend, option);
    public getFamilyList = (option?: IHttpRequest) => send(API.getFamilyList, option);
    public getFamilyDetail = (option?: IHttpRequest) => send(API.getFamilyDetail, option, "get");
    public updateFamily = (option?: IHttpRequest) => send(API.updateFamily, option);
    public addFamily = (option?: IHttpRequest) => send(API.addFamily, option);
    public wechatOauth = (option?: IHttpRequest) => send(API.wechatOauth, option);
    public forgetPassword = (option?: IHttpRequest) => send(API.forgetPassword, option);
    public deleteFamilyMember = (option?: IHttpRequest) => send(API.deleteFamilyMember, option);
    public getOrderList = (option?: IHttpRequest) => send(API.getOrderList, option);
    public getOrderDetail = (option?: IHttpRequest) => send(API.getOrderDetail, option, "get");
    public proceedPay = (option?: IHttpRequest) => send(API.proceedPay, option, "get");
    public cancelOrder = (option?: IHttpRequest) => send(API.cancelOrder, option, "get");
    public modifiedOrderDate = (option?: IHttpRequest) => send(API.modifiedOrderDate, option);
    public getReportList = (option?: IHttpRequest) => send(API.getReportList, option);
    public getReportJson = (option?: IHttpRequest) => send(API.getReportJson, option, "get");
    public getReportPDF = (option?: IHttpRequest) => send(API.getReportPDF, option, "get");
    public greenwayMallPageList = (option?: IHttpRequest) => send(API.greenwayMallPageList, option);
    public getDoctor = (option?: IHttpRequest) => send(API.getDoctor, option, "get");
    // 绿通
    public addGreenwayOrder = (option?: IHttpRequest) => send(API.addGreenwayOrder, option);
    public h5CancelGreenwayOrder = (option?: IHttpRequest) => send(API.h5CancelGreenwayOrder, option, "get");
    public h5OrdeInfoGreenwayOrder = (option?: IHttpRequest) => send(API.h5OrdeInfoGreenwayOrder, option, "get");
    public h5QueryGreenwayOrder = (option?: IHttpRequest) => send(API.h5QueryGreenwayOrder, option);
    public h5ProceedPayGreenwayOrder = (option?: IHttpRequest) => send(API.h5ProceedPayGreenwayOrder, option, "get");
    public getGreenwayItem = (option?: IHttpRequest) => send(API.getGreenwayItem, option, "get");
    // 细胞存储
    public addCellOrder = (option?: IHttpRequest) => send(API.addCellOrder, option);
    public h5CancelCellOrder = (option?: IHttpRequest) => send(API.h5CancelCellOrder, option, "get");
    public h5OrderCellOrder = (option?: IHttpRequest) => send(API.h5OrderCellOrder, option, "get");
    public h5QueryCellOrder = (option?: IHttpRequest) => send(API.h5QueryCellOrder, option);
    public h5ProceedPayCellOrder = (option?: IHttpRequest) => send(API.h5ProceedPayCellOrder, option, "get");
    public getServiceItems = (option?: IHttpRequest) => send(API.getServiceItems, option);
    public getServiceItemInfo = (option?: IHttpRequest) => send(API.getServiceItemInfo, option, "get");
    public getCellItem = (option?: IHttpRequest) => send(API.getCellItem, option, "get");
    // 获取地址
    public getCascaderh5 = (option?: IHttpRequest) => send(API.getCascaderh5, option, "get");
    public getMyUsingServiceNums = (option?: IHttpRequest) => send(API.getMyUsingServiceNums, option, "get");
    public getMyRights = (option?: IHttpRequest) => send(API.getMyRights, option, "get");
    public getMyRightsRecords = (option?: IHttpRequest) => send(API.getMyRightsRecords, option, "get");
    public getNoticeList = (option?: IHttpRequest) => send(API.getNoticeList, option, "get");
    public readNotice = (option?: IHttpRequest) => send(API.readNotice, option);

    // 意见反馈
    public addSuggest = (option?: IHttpRequest) => send(API.addSuggest, option);
    public getHomeUrl = (option?: IHttpRequest) => send(API.getHomeUrl, option,"get");

    // 线下报告解读服务
    public addOfflineReportExplanation = (option?: IHttpRequest) => send(API.addOfflineReportExplanation, option);
    public cancelOfflineReportExplanation = (option?: IHttpRequest) => send(API.cancelOfflineReportExplanation, option,"get");
    public offlineReportExplanationList = (option?: IHttpRequest) => send(API.offlineReportExplanationList, option);
    public getOfflineReportExplanation = (option?: IHttpRequest) => send(API.getOfflineReportExplanation, option,"get");
      
    // 线上报告解读
    public activationDiagnosis = (option?: IHttpRequest) => send(API.activationDiagnosis, option);
    
    // 获取用户是否已经激活问诊套餐卡
    public getMemberDiagnosis = (option?: IHttpRequest) => send(API.getMemberDiagnosis, option,"get");
    
}
