const reportLogin = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/report-login.vue")), "report");
const privacyFile = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/privacy-file.vue")), "report");
const reportList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/report-list.vue")), "report");
const reportDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/report-detail.vue")), "report");
const sportPdf = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/report/sport-pdf.vue")), "report");

export default
[
    {
        path: "/report/login",
        name: "reportLogin",
        component: reportLogin,
        meta:
        {
            tabbarName: "reportLogin",
            keepAlive: true
        }
    },
    {
        path: "/privacy/file",
        name: "privacyFile",
        component: privacyFile
    },
    {
        path: "/report/list",
        name: "reportList",
        component: reportList
    },
    {
        path: "/report/detail",
        name: "reportDetail",
        component: reportDetail
    },
    {
        path: "/sport/pdf",
        name: "sportPdf",
        component: sportPdf
    }
];
