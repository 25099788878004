const globalSetting = (<any>window).globalSetting;

const globalConfig =
{
    server:  globalSetting.apiServer,
    serverTel:  globalSetting.serverTel,
    serverCompany:  globalSetting.serverCompany,
    serverReport:  globalSetting.serverReport,
    enterprisePlaceholder:  globalSetting.enterprisePlaceholder,
    tip:  globalSetting.tip,
    noEditDependents: globalSetting.noEditDependents
};

export default globalConfig;
