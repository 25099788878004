
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import dayjs from "dayjs";

/**
 * 时间选择控件
 * @class
 * @version 1.0.0
 */
@Component
export default class DatetimePicker extends Vue
{
    /**
     * 是否显示
     * @private
     * @returns string
     */
    @Prop({type: String, default: "bottom"})
    private popPosition: string;

    /**
     * picker选择框确认按钮文字
     * @private
     * @returns string
     */
    @Prop({type: String, default: "确认"})
    private confirmText: string;
    
    /**
     * picker选择框取消按钮文字
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private cancelText: string;
    
    /**
     * 顶部栏标题
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private toolbarTitle: string;
    
    /**
     * 顶部栏标题是否显示
     * @private
     * @returns boolean
     */
    @Prop({type: Boolean, default: true})
    private showToolbar: boolean;
    
    /**
     * 输入框 label
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private inputLabel: string;
    
    /**
     * 输入框空白时显示
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private inputPlaceholder: string;
    
    /**
     * 时间框最小值
     * @private
     * @returns number
     */
    // @Prop({type: Date, default: null})
    // private minDate: any;
    
    /**
     * 时间框最大值
     * @private
     * @returns number
     */
    @Prop({type: Date, default: ()=>new Date(dayjs().add(10, 'year').valueOf())})
    private maxDate: any;
    
    /**
     * 是否必填
     * @private
     * @returns boolean
     */
    @Prop({type: Boolean, default: false})
    private inputRequired: boolean;
    
    /**
     * vlue值显示位置
     * @private
     * @returns string
     */
    @Prop({type: String, default: "right"})
    private inputAlign: string;
    
    /**
     * 模型值-用于from表单赋值
     * @private
     * @returns string
     */
    @Prop({type: String, default: ""})
    private modelValue: string;
    
    /**
     * 是否禁用
     * @private
     * @returns boolean
     */
    @Prop({type: Boolean, default: false})
    private disabled: boolean;
    
    /**
     * 显示弹出层
     * @private
     */
    private showPopup: boolean = false;
    
    /**
     * 最新时间
     * @private
     */
    private currentDate: number = new Date().getTime();
    
    /**
     * 最新时间
     * @private
     * @returns string
     */
    private formatter(type, value) {
        if (type === 'year') {
            return `${value}年`;
        } 
        if (type === 'month') {
            return `${value}月`;
        }
        return value;
    }

    /**
     * 隐藏
     * @private
     */
    private onCancel (): void
    {
        this.showPopup = false;
    }

    /**
     * 确定按钮
     * @private
     */
    private onConfirm(event): void
    {
        this.showPopup = false;
		this.$emit('update:modelValue', dayjs(event).format("YYYY-MM-DD"));
    }

    /**
     * 选择
     * @private
     */
    private onShowPopup(): void
    {
        if (!this.disabled)
        {
            this.showPopup = true;
        }
    }

    /**
     * 每一项变更
     * @private
     */
    private onInput(event): void
    {
        if (!this.showToolbar)
        {
            this.onConfirm(event);
        }
    }
    
}
