
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 意见反馈
 * @class
 * @version 1.0.0
 */
export default class SuggestService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return UserService
     */
    private static _instance: SuggestService;

    /**
     * 服务实例
     * @static
     * @property
     * @returns UserService
     */
    public static get instance(): SuggestService
    {
        if(!this._instance)
        {
            this._instance = new SuggestService();
        }
        
        return this._instance;
    }

    /**
     * 意见反馈
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async addSuggest(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.addSuggest({
            data: data
        });

        return response;
    }
}
