
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 细胞存储服务。
 * @class
 * @version 1.0.0
 */
export default class CellService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return UserService
     */
    private static _instance: CellService;

    /**
     * 服务实例
     * @static
     * @property
     * @returns UserService
     */
    public static get instance(): CellService
    {
        if(!this._instance)
        {
            this._instance = new CellService();
        }
        
        return this._instance;
    }

    /**
     * 新增细胞存储项目订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async addCellOrder(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.addCellOrder({
            data: data
        });

        return response;
    }

    /**
     * 取消细胞存储项目订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5CancelCellOrder(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.h5CancelCellOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取细胞存储项目订单详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5OrderCellOrder(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.h5OrderCellOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取细胞存储订单分页列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5QueryCellOrder(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.h5QueryCellOrder({
            data: data
        });

        return response;
    }

    /**
     * 细胞存储继续支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5ProceedPayCellOrder(id: number): Promise<IHttpResponse>
    {
        let response = await this.apis.h5ProceedPayCellOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取细胞存详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getCellItem(): Promise<IHttpResponse>
    {
        let response = await this.apis.getCellItem({});

        return response;
    }
}
