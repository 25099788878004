
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 登录服务。
 * @class
 * @version 1.0.0
 */
export default class UserService extends ServiceBase
{
    /**
     * 用户服务实例
     * @private
     * @return UserService
     */
    private static _instance: UserService;

    /**
     * 用户实例
     * @static
     * @property
     * @returns UserService
     */
    public static get instance(): UserService
    {
        if(!this._instance)
        {
            this._instance = new UserService();
        }
        
        return this._instance;
    }

    /**
     * 登录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async login(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.login({
            data: data
        });

        return response;
    }

    /**
     * 修改密码
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async forgetPassword(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.forgetPassword({
            data: data
        });

        return response;
    }

    /**
     * 根据公司code获取公司信息
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrgIngo(code: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrgIngo({
            params: {code}
        });

        return response;
    }

    /**
     * 获取首页数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHomeInfo(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getHomeInfo({data});

        return response;
    }

    /**
     * 发送验证码
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async smsSend(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.smsSend({
            data: data
        });

        return response;
    }

    /**
     * 微信授权
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async wechatOauth(url: any): Promise<IHttpResponse>
    {
        let response = await this.apis.wechatOauth({
            data:
            {
                url: url
            }
        });

        return response;
    }

    /**
     * 获取地址
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getCascaderh5(): Promise<IHttpResponse>
    {
        let response = await this.apis.getCascaderh5({});

        return response;
    }

    /**
     * 获取权益数量
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getMyUsingServiceNums(): Promise<IHttpResponse>
    {
        let response = await this.apis.getMyUsingServiceNums({});

        return response;
    }

    /**
     * 获取我使用中的权益 0: 生效中； 1：已失效
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getMyRights(status:number): Promise<IHttpResponse>
    {
        let response = await this.apis.getMyRights({
            params:{status}
        });

        return response;
    }

    /**
     * 获取我的权益使用记录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getMyRightsRecords(): Promise<IHttpResponse>
    {
        let response = await this.apis.getMyRightsRecords({});

        return response;
    }

    /**
     * 获取消息通知列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
     public async getNoticeList(): Promise<IHttpResponse>
     {
         let response = await this.apis.getNoticeList({});
 
         return response;
     }
 
    /**
      * 公告已读
      * @public
      * @param {params}
      * @returns {Promise<IHttpResponse>}
      */
     public async readNotice(id: number): Promise<IHttpResponse>
     {
         let response = await this.apis.readNotice({data:{noticeIds:[id]}});
 
         return response;
     }

    /**
      * 公告已读
      * @public
      * @param {params}
      * @returns {Promise<IHttpResponse>}
      */
     public async getHomeUrl(): Promise<IHttpResponse>
     {
         let response = await this.apis.getHomeUrl({});
 
         return response;
     }
}
