

























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";

@Component
export default class FamilyList extends Vue
{
    /**
     * 是否显示
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default: false})
    private value: boolean;

    /**
     * 当前选中用户
     * @private
     * @returns string
     */
    @Prop({type: Number})
    private selectUserId: number;

    /**
     * 是否显示日期弹框 
     * @private
     * @returns boolean
     */
    private get showUserPopup(): boolean
    {
        return this.value;
    }

    /**
     * 返回弹框状态 
     * @private
     */
    private set showUserPopup(value: boolean)
    {
        this.$emit("input", value);
    }

    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
    @Watch("selectUserId")
    private onSelectUserIdChange(value: number): void
    {
        if(value)
        {
            this.currentIndex = this.familyList.findIndex(item=>item.id==value);
        }
    }

    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected currentIndex: any = 0;

    /**
     * 家属列表
     * @protected
     * @returns Array<any>
     */
    protected familyList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    protected getRelationship(value: number): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 当前选中用户
     * @private
     * @returns void
     */
    protected onSelectUser(index: number): void
    {
        this.currentIndex = index;
    }

    /**
     * 添加用户
     * @protected
     * @returns string
     */
    protected addUser(): void
    {
        this.$router.push({name: "familyList"});
    }

    /**
     * 获取体检用户家属数据列表
     * @private
     * @returns void
     */
    protected async checkFamilyList(): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyList();

            if(result.data)
            {
                this.familyList = result.data;
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 确认切换用户
     * @private
     * @returns void
     */
    protected onConfirmUser(): void
    {
        this.$emit("getSelectUser", this.familyList[this.currentIndex]);
        this.showUserPopup = false;
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
    protected created()
    {
        this.checkFamilyList();
    }
    
}
