
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 权益服务。
 * @class
 * @version 1.0.0
 */
export default class RigthsService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return RigthsService
     */
    private static _instance: RigthsService;

    /**
     * 权益实例
     * @static
     * @property
     * @returns RigthsService
     */
    public static get instance(): RigthsService
    {
        if(!this._instance)
        {
            this._instance = new RigthsService();
        }
        
        return this._instance;
    }

    /**
     * 获取权益列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getServiceItems(): Promise<IHttpResponse>
    {
        let response = await this.apis.getServiceItems({});

        return response;
    }

    /**
     * 获取权益列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getServiceItemInfo(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getServiceItemInfo({
            params:{
                id
            }
        });

        return response;
    }
    
}
