import Type from "src/utils/type";

export
{
    RELATION,
    ID_TYPE,
    MARRIAGE_STATUS,
    CHECK_STATUS
};

/**
 * 与本人关系
 * @enum
 * @version 1.0.0
 */
enum RELATION
{
    /**
     * 父母
     * @member
     */
    parents = 1,

    /**
     * 配偶
     * @member
     */
    spouse,

    /**
     * 子女
     * @member
     */
    child,

    /**
     * 其他
     * @member
     */
    other

}

Type.setMetadata(RELATION,
{
   
    parents:
    {
        description: "父母"
    },
    spouse:
    {
        description: "配偶"
    },
    child:
    {
        description: "子女"
    },
    other:
    {
        description: "其他"
    }
});

/**
 * 证件类型
 * @enum
 * @version 1.0.0
 */
enum ID_TYPE
{
    /**
     * 身份证
     * @member
     */
    id_card = 1,

    /**
     * 护照
     * @member
     */
    passport,
    
    /**
     * 回乡证
     * @member
     */
    home,

    /**
     * 台胞证
     * @member
     */
    taiwan
   
}

Type.setMetadata(ID_TYPE,
{
    id_card:
    {
        description: "身份证"
    },
    passport:
    {
        description: "护照"
    },
    home:
    {
        description: "回乡证"
    },
    taiwan:
    {
        description: "台胞证"
    }
});

/**
 * 婚姻状态
 * @enum
 * @version 1.0.0
 */
enum MARRIAGE_STATUS
{
    /**
     * 已婚
     * @member
     */
    married = 1,

    /**
     * 未婚
     * @member
     */
    unmarried
}

Type.setMetadata(MARRIAGE_STATUS,
{
    married:
    {
        description: "已婚"
    },
    unmarried:
    {
        description: "未婚"
    }
});

/**
 * 下单结果
 * @enum
 * @version 1.0.0
 */
enum CHECK_STATUS
{
    /**
     * 已下单
     * @member
     */
    ordered = 1,

    /**
     * 下单成功，不需要调整支付页面
     * @member
     */
    success = 2,

    /**
     * 下单成功，需要跳转微信支付页面
     * @member
     */
    successJump = 3,

    /**
     * 下单失败
     * @member
     */
    fail = 4,

    /**
     * 体检项目为空
     * @member
     */
    emptyProject = 5,

    /**
     * 客户信息不全
     * @member
     */
    userInfo = 6,

    /**
     * 机构信息、合同信息、套餐信息有误,
     * @member
     */
    org = 7,

    /**
     * 无排期或无号
     * @member
     */
    noDate = 8,

    /**
     * 体检日期已过
     * @member
     */
    checkedDate = 9
}

Type.setMetadata(CHECK_STATUS,
{
    ordered:
    {
        description: "已下单"
    },
    success:
    {
        description: "下单成功"
    },
    successJump:
    {
        description: "下单成功"
    },
    fail:
    {
        description: "下单失败"
    },
    emptyProject:
    {
        description: "体检项目为空"
    },
    userInfo:
    {
        description: "体检人信息不全"
    },
    org:
    {
        description: "机构信息、合同信息、套餐信息有误"
    },
    noDate:
    {
        description: "无排期或无号"
    },
    checkedDate:
    {
        description: "体检日期已过"
    }
});
