
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 绿通服务。
 * @class
 * @version 1.0.0
 */
export default class ChannelService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return UserService
     */
    private static _instance: ChannelService;

    /**
     * 用户实例
     * @static
     * @property
     * @returns UserService
     */
    public static get instance(): ChannelService
    {
        if(!this._instance)
        {
            this._instance = new ChannelService();
        }
        
        return this._instance;
    }

    /**
     * 不分页
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async greenwayMallPageList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.greenwayMallPageList({
            data: data
        });

        return response;
    }

    /**
     * 获取医生实体类
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getDoctor(id): Promise<IHttpResponse>
    {
        let response = await this.apis.getDoctor({
            urlPath: {id}
        });

        return response;
    }

    /**
     * 新增绿通项目订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async addGreenwayOrder(data): Promise<IHttpResponse>
    {
        let response = await this.apis.addGreenwayOrder({
            data: data
        });

        return response;
    }

    /**
     * 取消绿通项目订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5CancelGreenwayOrder(id): Promise<IHttpResponse>
    {
        let response = await this.apis.h5CancelGreenwayOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取绿通项目订单实体类
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5OrdeInfoGreenwayOrder(id): Promise<IHttpResponse>
    {
        let response = await this.apis.h5OrdeInfoGreenwayOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取绿通项目订单列表数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5QueryGreenwayOrder(data): Promise<IHttpResponse>
    {
        let response = await this.apis.h5QueryGreenwayOrder({
            data: data
        });

        return response;
    }

    /**
     * 绿通继续支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async h5ProceedPayGreenwayOrder(id): Promise<IHttpResponse>
    {
        let response = await this.apis.h5ProceedPayGreenwayOrder({
            params: {id}
        });

        return response;
    }

    /**
     * 获取绿通项目信息
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getGreenwayItem(data): Promise<IHttpResponse>
    {
        let response = await this.apis.getGreenwayItem({
            params: data
        });

        return response;
    }
}
