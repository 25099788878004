













import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue
{
    protected created(): void
    {
        let title = this.orgInfo && this.orgInfo.orgName || "";
        document.title = title;
    }

    /**
     * loading次数
     * @private
     * @returns string
     */
    private get loadingCount(): number
    {
        return this.$store.state.loadingCount || 0;
    }

    /**
     * 获取企业信息
     * @private
     * @returns any
     */
    private get orgInfo(): any
    {
        let orgInfoStorge = localStorage.getItem("orgInfo");
        if(orgInfoStorge)
        {
            return JSON.parse(orgInfoStorge)
        }
        return {};
    }
}
