
import globalConfig from "../config/index";

const apiUrl = globalConfig.server;

export default
{
    login:                           `${apiUrl}/api/auth/login`,                                    // 登录
    getOrgIngo:                      `${apiUrl}/api/auth/orginfo`,                                  // 根据公司code获取公司信息
    getHomeInfo:                     `${apiUrl}/api/homePage/info`,                                 // 获取首页数据
    getpackageList:                  `${apiUrl}/api/package/h5OtherPackageList`,                    // 获取眼科/齿科套餐列表
    getMedicalpackage:               `${apiUrl}/api/package/h5MemberPackages`,                          // 获取体检套餐列表
    getPackageInfo:                  `${apiUrl}/api/package/packageInfo`,                           // 获取套餐详情
    getWechatJSSDK:                  `${apiUrl}/api/jssdk/config`,                                  // 获取微信jssdk
    getHospitalList:                 `${apiUrl}/api/hospital/h5Query`,                              // 获取机构详情
    locationCity:                    `${apiUrl}/api/region/citylist`,                               // 获取切换定位城市
    getMedicalOrgDateInfo:           `${apiUrl}/api/package/h5GetSchedule`,                         // 获取某个机构排期
    submitOrder:                     `${apiUrl}/api/reserveOrder`,                                  // 新增预约订单
    smsSend:                         `${apiUrl}/api/sms/send`,                                      // 发送短信
    getFamilyList:                   `${apiUrl}/api/member/h5Query`,                                // 获取体检数据列表
    getFamilyDetail:                 `${apiUrl}/api/member/h5Family`,                               // 获取体检用户信息详情
    updateFamily:                    `${apiUrl}/api/member/h5UpdateFamily`,                         // 修改体检用户家属数据
    addFamily:                       `${apiUrl}/api/member/h5AddFamily`,                            // 新增常用体检人
    wechatOauth:                     `${apiUrl}/oauth/wechat`,                                      // 微信授权
    forgetPassword:                  `${apiUrl}/api/member/h5ForgetPwd`,                             // 找回密码
    deleteFamilyMember:              `${apiUrl}/api/member/h5DelFamily`,                            // 删除体检用户信息
    getReportList:                   `${apiUrl}/api/Report/query`,                                   // 获取报告列表
    getReportPDF:                    `${apiUrl}/api/Report/PDFReport`,                               // 获取PDF报告
    greenwayMallPageList:            `${apiUrl}/api/doctor/queryGreenwayMall`,                       // 获取绿通分页数据
    getOrderList:                    `${apiUrl}/api/reserveOrder/h5Query`,                            // 获取预约订单列表
    getOrderDetail:                  `${apiUrl}/api/reserveOrder/h5OrderInfo`,                             // 获取订单详情
    proceedPay:                      `${apiUrl}/api/reserveOrder/h5ProceedPay`,                      // 继续支付
    cancelOrder:                     `${apiUrl}/api/reserveOrder/h5Cancel`,                                // 取消订单
    modifiedOrderDate:               `${apiUrl}/api/reserveOrder/h5ChangeReserve`,                         // 修改订单预约时间
    getReportJson:                  `${apiUrl}/api/Report/JSONReport`,                              // 获取json报告
    getServiceItems:                 `${apiUrl}/api/rights/getServiceItems`,                         // 获取首页权益数据
    getServiceItemInfo:             `${apiUrl}/api/rights/getServiceItemInfo`,                              // 获取权益服务详情
    getDoctor:                       `${apiUrl}/api/doctor/H5Doctor/{id}`,                              // 获取医生实例
    
    // 绿通订单
    addGreenwayOrder:                `${apiUrl}/api/greenwayOrder`,                                     // 新增绿通项目订单
    h5CancelGreenwayOrder:           `${apiUrl}/api/greenwayOrder/h5Cancel`,                            // 取消绿通项目订单
    h5OrdeInfoGreenwayOrder:         `${apiUrl}/api/greenwayOrder/h5OrdeInfo`,                          // 获取绿通项目订单实体类
    h5QueryGreenwayOrder:            `${apiUrl}/api/greenwayOrder/h5Query`,                             // 获取绿通项目订单列表数据
    h5ProceedPayGreenwayOrder:       `${apiUrl}/api/greenwayOrder/h5ProceedPay`,                        // 绿通继续支付
    getGreenwayItem:                 `${apiUrl}/api/doctor/getGreenwayItem`,                            // 获取绿通项目信息
    
    // 细胞存储
    addCellOrder:                    `${apiUrl}/api/cellOrder`,                                         // 新增细胞存储项目订单
    h5CancelCellOrder:               `${apiUrl}/api/cellOrder/h5Cancel`,                                // 取消细胞存储项目订单
    h5OrderCellOrder:                `${apiUrl}/api/cellOrder/h5Order`,                                 // 获取细胞存储项目订单详情
    h5QueryCellOrder:                `${apiUrl}/api/cellOrder/h5Query`,                                 // 获取细胞存储订单分页列表
    h5ProceedPayCellOrder:           `${apiUrl}/api/cellOrder/h5ProceedPay`,                            // 细胞存储继续支付
    getCellItem:                     `${apiUrl}/api/cellItem/h5CellInfo`,                               // 获取细胞存储项目

    getCascaderh5:                    `${apiUrl}/api/region/h5`,                                        // 获取省市区联动,
    getMyUsingServiceNums:            `${apiUrl}/api/member/getMyUsingServiceNums`,                     // 获取我使用中的权益数量
    getMyRights:                      `${apiUrl}/api/member/getMyRights`,                               // 获取我使用中的权益
    getMyRightsRecords:               `${apiUrl}/api/member/getMyRightsRecords`,                        // 获取我的权益使用记录
    getNoticeList:                    `${apiUrl}/api/h5notice/list`,                                    // 获取消息列表
    readNotice:                       `${apiUrl}/api/h5notice/read`,                                    // 获取消息列表

    // 意见反馈
    addSuggest:                       `${apiUrl}/api/suggest`,                                          // 添加意见反馈
    uploadFile:                       `${apiUrl}/api/file/upload`,                                      // 上传文件
    getHomeUrl:                       `http://elephantdrapi.withealth.cc:8033/api/Elephantdr/getHomeUrl`,                            // 

    // 线下报告解读服务
    addOfflineReportExplanation:      `${apiUrl}/api/offlineReportExplanation/h5add`,                   // 新增 
    cancelOfflineReportExplanation:   `${apiUrl}/api/offlineReportExplanation/h5Cancel`,                // 取消
    offlineReportExplanationList:     `${apiUrl}/api/offlineReportExplanation/h5List`,                  // 获取某个用户线下报告列表 
    getOfflineReportExplanation:      `${apiUrl}/api/offlineReportExplanation/h5Get`,                   // 获取线下报告解读详情 
    
    // 线上报告解读
    activationDiagnosis:              `${apiUrl}/api/reserveOrder/activationDiagnosis`,                 // 激活线上问诊套餐 
    
    // 获取用户是否已经激活问诊套餐卡
    getMemberDiagnosis:               `${apiUrl}/api/reserveOrder/getMemberDiagnosis`,                  // 获取用户是否已经激活问诊套餐卡 
};
