const cellStockpileProductDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/cellStockpile/product-detail.vue")), "cellStockpile");
const cellStockpileOperation = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/cellStockpile/operation.vue")), "cellStockpile");
const cellStockpileMakeSuccss = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/cellStockpile/makeSuccss.vue")), "cellStockpile");
const cellStockpileOrderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/cellStockpile/order-list.vue")), "cellStockpile");
const cellStockpileOrderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/cellStockpile/order-detail.vue")), "cellStockpile");


export default
[
    {
        path: "/cellStockpile/product-detail",
        name: "cellStockpile-product-detail",
        component: cellStockpileProductDetail
    },
    {
        path: "/cellStockpile/operation",
        name: "cellStockpile-operation",
        component: cellStockpileOperation
    },
    {
        path: "/cellStockpile/makeSuccss",
        name: "cellStockpile-makeSuccss",
        component: cellStockpileMakeSuccss
    },
    {
        path: "/cellStockpile/order-list",
        name: "cellStockpile-order-list",
        component: cellStockpileOrderList
    },
    {
        path: "/cellStockpile/order-detail",
        name: "cellStockpile-order-detail",
        component: cellStockpileOrderDetail
    }
    
];
