const channelList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/channel/channelList.vue")), "channel");
const channelSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/channel/submit.vue")), "channel");
const channelOrderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/channel/order-list.vue")), "channel");
const channelOrderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/channel/order-detail.vue")), "channel");

export default
[
    {
        path: "/channel/list",
        name: "channel",
        component: channelList
    },
    {
        path: "/channel/submit",
        name: "channel-submit",
        component: channelSubmit
    },
    {
        path: "/channel/order-list",
        name: "channel-order-list",
        component: channelOrderList
    },
    {
        path: "/channel/order-detail",
        name: "channel-order-detail",
        component: channelOrderDetail
    }
];
