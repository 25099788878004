import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state:
    {
        orgInfo: null, // 机构信息
        userInfo: null, // 用户信息
        loadingCount: 0,
        historyCityList: [], // 选择历史城市集合
        priceIsDispaly: null,
        selectUser: null,
        selectOptionalItem: null,
        company: null,
        selectAddress: null,
        location: null

    },
    mutations:
    {
        // 修改机构信息
        SET_ORG_INFO(state: any, data: any)
        {
            state.orgInfo = data;
            if(data)
            {
                localStorage.setItem("orgInfo",JSON.stringify(data));
            }
            else
            {
                localStorage.removeItem("orgInfo");
            }
            
        },
        SET_HISTORTY_CITY_LIST(state: any, data: Array<string>)
        {
            state.historyCityList = data;

            localStorage.setItem("historyCityList",JSON.stringify(data));
        },
        SET_SELECT_ADDRESS(state: any, data: any)
        {
            state.selectAddress = data;
            if(data)
            {
                localStorage.setItem("selectAddress",JSON.stringify(data));
            }
            else
            {
                localStorage.removeItem("selectAddress");
            }

        },
        SET_LOCATION(state: any, data: any)
        {
            state.location = data;

            localStorage.setItem("location",JSON.stringify(data));
        },
        // 用户信息
        SET_USER_INFO(state: any, data: any)
        {
            state.userInfo = data;
            localStorage.setItem("userInfo", JSON.stringify(data));
        },
        // 价格显示信息
        SET_PRICE_DISPLAY(state: any, data: any)
        {
            state.priceIsDispaly = data;
            localStorage.setItem("priceIsDispaly", JSON.stringify(data));
        },
        // 选中的用户
        SET_SELECT_USER(state: any, data: any)
        {
            state.selectUser = data;
            localStorage.setItem("selectUser", JSON.stringify(data));
        },
        // 选中自选项目
        SET_SELECT_OPTIONAL_ITEM(state: any, data: any)
        {
            state.selectOptionalItem = data;
            localStorage.setItem("selectOptionalItem", JSON.stringify(data));
        },
        // 修改公司信息
        SET_COMPANY(state: any, data: any)
        {
            state.company = data;
            localStorage.setItem("company", JSON.stringify(data));
        },
        // 修改数量
        SET_LOADING_COUNT(state: any, data: number)
        {
            state.loadingCount = data;
        }
    },
    actions:
    {
        // 修改机构信息
        setOrgInfo(store: any, data: any)
        {
            store.commit("SET_ORG_INFO", data);
        },
        // 修改历史数据
        setHistoryCityList(store: any, data: string)
        {
            store.commit("SET_HISTORTY_CITY_LIST", data);
        },
        // 修改选中的地址
        setSelectAddress(store: any, data: string)
        {
            store.commit("SET_SELECT_ADDRESS", data);
        },
        // 修改当前位置信息
        setLocation(store: any, data: any)
        {
            store.commit("SET_LOCATION", data);
        },
        setUserInfo(store: any, data: any)
        {
            store.commit("SET_USER_INFO", data);
        },
        // 价格显示信息
        setPriceIsDispaly(store: any, data: any)
        {
            store.commit("SET_PRICE_DISPLAY", data);
        },
        // 选中的用户
        setSelectUser(store: any, data: any)
        {
            store.commit("SET_SELECT_USER", data);
        },
        setSelectOptionalItem(store: any, data: any)
        {
            store.commit("SET_SELECT_OPTIONAL_ITEM", data);
        },
        setCompany(store: any, data: any)
        {
            store.commit("SET_COMPANY", data);
        },
        setLoadingCount(store: any, count: number)
        {
            store.commit("SET_LOADING_COUNT", count);
        }
    },
    getters:
    {
        // 获取机构信息
        orgInfo(state: any)
        {
            if(!state.orgInfo)
            {
                state.orgInfo = JSON.parse(localStorage.getItem("orgInfo"));
            }

            return state.orgInfo;
        },
        historyCityList(state: any)
        {
            console.log(state.historyCityList);
            if(state.historyCityList.length === 0)
            {
                state.historyCityList = JSON.parse(localStorage.getItem("historyCityList")) || [];
            }

            return state.historyCityList;
        },
        selectAddress(state: any)
        {
            if(!state.selectAddress)
            {
                state.selectAddress = JSON.parse(localStorage.getItem("selectAddress"));
            }

            return state.selectAddress;
        },
        location(state: any)
        {
            if(!state.location)
            {
                state.location = JSON.parse(localStorage.getItem("location")) || null;
            }

            return state.location;
        },
        userInfo(state: any)
        {
            if(!state.userInfo)
            {
                state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
            }

            return state.userInfo;
        },
        priceIsDispaly(state: any)
        {
            if(!state.priceIsDispaly)
            {
                state.priceIsDispaly = JSON.parse(localStorage.getItem("priceIsDispaly"));
            }

            return state.priceIsDispaly;
        },
        selectUser(state: any)
        {
            if(!state.selectUser)
            {
                state.selectUser = JSON.parse(localStorage.getItem("selectUser"));
            }

            return state.selectUser;
        },
        selectOptionalItem(state: any)
        {
            if(!state.selectOptionalItem)
            {
                state.selectOptionalItem = JSON.parse(localStorage.getItem("selectOptionalItem"));
            }

            return state.selectOptionalItem;
        },
        company(state: any)
        {
            if(!state.company)
            {
                state.company = JSON.parse(localStorage.getItem("company"));
            }

            return state.company;
        }
    },
    modules:
    {
    }
});
